// Если нам надо оплучить глобальные данные
// import { getters } from '@/store/store';
// Если нам надо обработать данные используем мутации
// import { mutations } from './mutations';

export default {
	getOSMembers(parametrs, currentYear) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`MedicalOrgs/GetLK_Minzdrav?year=${currentYear}`, parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getSystemYearData() {
		return new Promise((resolve, reject) => {
			this.$http
				.get('Region/GetSystemYearData')
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
