<template>
	<div class="flex flex-col w-screen sm:w-auto px-2 py-2 sm:px-6 sm:py-6 gap-5">
		<!-- Заголовок -->
		<h1 class="flex w-full text-xl h-6 mb-4 sm:mb-7 font-extrabold sm:text-2xl">Субъекты Российской Федерации</h1>
		<!-- Таблица -->
		<TableMZ />
	</div>
</template>

<script>
// Глобальные данные стора
import { getters } from '@/store/store';

import TableMZ from './TableMZ.vue';

export default {
	name: 'Main',
	components: { TableMZ },
	computed: {
		...getters,
		chairman() {
			return this.getAuthUser.roles.indexOf('OS_Chairman') !== -1;
		},
		secretary() {
			return this.getAuthUser.roles.indexOf('OS_Secretary') !== -1;
		},
		calcHref() {
			return this.chairman
				? 'https://nok.minzdrav.gov.ru/docs/Оценка ОС. Инструкция пользователя председатель ОС.pdf'
				: 'https://nok.minzdrav.gov.ru/docs/Оценка ОС. Инструкция пользователя секретарь ОС.pdf';
		}
	}
};
</script>

<style scoped>
.white-round-box {
	@apply sm:px-10 sm:py-8 rounded-lg bg-white filter drop-shadow-default;
}
</style>
