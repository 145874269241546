<template>
	<div class="flex items-center flex-wrap">
		<div class="flex flex-col relative text-gray-500">
			<div class="filter-select w-72 pt-3 px-5">
				<input
					class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
					v-model="search"
					@input="insertWords(search)"
					type="text"
					:placeholder="placeholder"
				/>
				<!-- <textarea
					class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
					v-model="search"
					@input="insertWords(search)"
                    :rows="selectFlag ? 'auto' : 1"
					type="text"
					:placeholder="placeholder"
				/> -->
			</div>
			<ul
				class="autocomplete-overflow overflow-y-auto text-left bg-gray-100 ml-5 pt-2 pb-2 rounded absolute top-14 left-0"
				:class="{
					hidden: search.length === 0,
					'border border-gray-100': ulList.length > 0,
					[widthStroke]: ulList.length > 0
				}"
				:style="ulList.length > 3 ? `height: ${heightUl}` : ''"
			>
				<li v-for="(item, ind) in ulList" :key="ind" v-show="ulList.length > 0" class="w-full px-2 py-2 bg-gray-100 cursor-pointer hover:underline hover:text-blue-500" @click="selected(item)">
					<span class="w-full">{{ item[itemKey] }}</span>
				</li>
				<li v-show="ulList.length === 0 && !selectFlag && search.length >= timeout" class="px-2 py-2 bg-gray-100">Поиск не дал результатов...</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		list: {
			type: Array,
			default: () => []
		},
		placeholder: {
			type: String,
			default: () => 'Введите текст...'
		},
		value: {
			type: String,
			default: () => ''
		},
		itemKey: {
			type: String,
			default: () => ''
		},
		widthStroke: {
			type: String,
			default: () => 'w-62'
		},
		heightUl: {
			type: String,
			default: () => '110px'
		},
		timeout: {
			type: Number,
			default: () => 0
		}
	},
	data: () => ({
		search: '',
		ulList: [],
		selectFlag: false
	}),
	methods: {
		insertWords(text) {
			this.selectFlag = false;
			this.$emit('input', text);

			if (text.length === 0) {
				this.ulList = [];

				return;
			}

			if (text.length >= this.timeout) {
				this.ulList = this.list.filter((item) => item[this.itemKey].toLowerCase().includes(text.toLowerCase()));
			}
		},
		selected(item) {
			this.selectFlag = true;
			this.search = item[this.itemKey];
			this.ulList = [];
			this.$emit('input', item[this.itemKey]);

			this.$emit('query', this.search);
		}
	},
	created() {
		if (this.value) {
			this.search = this.value;
			this.selectFlag = true;
		}
	}
};
</script>

<style scoped>
ul > li {
	white-space: break-spaces;
}
</style>
